import React, { Component } from 'react';
import styled from 'styled-components';
import '../../App.css';

const NormalWhiteLabel = styled.label`
    font-size: 20px;
    font-weight: normal;
    height: 100%;
    width: 20%;
    background-color: none;
    color: white;
    font-family: 'AmericanTypewriter';
`;

const DefaultWhiteLabel = styled.label`
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: black;
    color: white;
    font-family: 'AmericanTypewriter';
`;

const DefaultWhiteHeaderLabel = styled.label`
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: none;
    color: white;
    font-family: 'AmericanTypewriter';
`;

const DefaultNavWhiteLabel = styled.label`
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: black;
    color: white;
    transition: background-color 0.4s linear, color 0.4s linear;
    font-family: 'AmericanTypewriter';

    &:hover {
        cursor: pointer;
        background-color: white;
        color: black;
    }
`;

const NormalBlackLabel = styled.label`
    font-size: 16px;
    font-weight: bold;
    height: 100%;
    width: 100%;
    background-color: none;
    color: black;
    font-family: 'AmericanTypewriter';
`;

const DefaultBlackLabel = styled.label`
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: white;
    color: black;
    font-family: 'AmericanTypewriter';
`;

const DefaultBlackHeaderLabel = styled.label`
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: none;
    color: black;
    font-family: 'AmericanTypewriter';
`;

const DefaultNavBlackLabel = styled.label`
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: none;
    color: black;
    transition: background-color 0.4s linear, color 0.4s linear;
    font-family: 'AmericanTypewriter';

    &:hover {
        cursor: pointer;
        background-color: black;
        color: white;
    }
`;

const SmallBlackLabel = styled.label`
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: white;
    color: black;
    font-family: 'AmericanTypewriter';
`;

const SmallNavWhiteLabel = styled.label`
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    width: 20%;
    background-color: black;
    color: white;
    transition: background-color 0.4s linear, color 0.4s linear;
    font-family: 'AmericanTypewriter';

    &:hover {
        cursor: pointer;
        background-color: white;
        color: black;
    }
`;

const ChangeableWhiteLabel = styled.label`
    font-size: ${props => props.fontsize};
    font-weight: ${props => props.fontweight};
    height: 100%;
    width: 100%;
    background-color: none;
    color: white;
    font-family: 'AmericanTypewriter';
`;

class StandardWhiteLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <NormalWhiteLabel>
                {content}
            </NormalWhiteLabel>
        );
    }
}

class WhiteLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteLabel>
                {content}
            </DefaultWhiteLabel>
        );
    }
}

class WhiteHeaderLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteHeaderLabel>
                {content}
            </DefaultWhiteHeaderLabel>
        );
    }
}

class NavWhiteLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultNavWhiteLabel>
                {content}
            </DefaultNavWhiteLabel>
        );
    }
}

class StandardBlackLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <NormalBlackLabel>
                {content}
            </NormalBlackLabel>
        );
    }
}

class BlackLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackLabel>
                {content}
            </DefaultBlackLabel>
        );
    }
}

class BlackHeaderLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackHeaderLabel>
                {content}
            </DefaultBlackHeaderLabel>
        );
    }
}

class NavBlackLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultNavBlackLabel>
                {content}
            </DefaultNavBlackLabel>
        );
    }
}

class MobileBlackLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <SmallBlackLabel>
                {content}
            </SmallBlackLabel>
        );
    }
}

class MobileNavWhiteLabel extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <SmallNavWhiteLabel>
                {content}
            </SmallNavWhiteLabel>
        );
    }
}

class FlexibleWhiteLabel extends Component {
    render() {
        const { 
            content,
            fontsize,
            fontweight,
        } = this.props;

        return (
            <ChangeableWhiteLabel fontsize={fontsize} fontweight={fontweight}>
                {content}
            </ChangeableWhiteLabel>
        );
    }
}

export { StandardWhiteLabel, WhiteLabel, WhiteHeaderLabel, NavWhiteLabel, StandardBlackLabel, BlackLabel, BlackHeaderLabel, NavBlackLabel, MobileBlackLabel, MobileNavWhiteLabel, FlexibleWhiteLabel };