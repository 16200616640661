import React, { Component } from 'react';
import '../../App.css';
import styled from 'styled-components';
import { Header, MobileHeader } from '../generic/header';
import { WhiteH2, WhiteH3, WhiteH4, WhiteH5 } from '../generic/heading';
import Facebook from '../../assets/logos/facebook.png';
import Twitter from '../../assets/logos/twitter.png';
import Instagram from '../../assets/logos/instagram.png';
import YouTube from '../../assets/logos/youtube.png';

const WhiteLabel = styled.label`
    font-size: 24px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const MobileWhiteLabel = styled.label`
    font-size: 20px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: left;
`;

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: 0,
          windowHeight: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({ windowWidth, windowHeight });
    }

    mainContent(styles) {
        if (styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3) {
            return (
                <div className="full-page main-content-padding">
                    <div className="partial-page main-content-margin div-border">
                        <WhiteH2 content="BOOKING" />
                        <WhiteLabel style={{margin: 0}}><b>email: </b><a href="info@replicacovers.com">info@replicacovers.com</a></WhiteLabel>
                        <WhiteLabel><b>phone: </b>Lex on 07588 856281 or Pete on 07780508496</WhiteLabel>
                        <WhiteH3 content="Please drop us an email or ring Lex or Pete if you would like to make an enquiry" />
                        <hr size="1" width="50%" color="white" />
                        <WhiteH3 content="Extra songs can be learnt for a first dance at a wedding as long as enough notice is given." />
                        <WhiteH3 content="Extended sets" />
                        <WhiteH3 content="Special song requests" />
                        <WhiteH3 content="Stage lighting" />
                        <WhiteH3 content="Disco (with or without DJ)" />
                        {/* <hr size="1" width="50%" color="white" />
                        <WhiteLabel>We also offer a full 90's setlist as part of our 90's project - visit <a href="http://www.resurrection90s.com" target="_blank">www.resurrection90s.com</a></WhiteLabel> */}
                    </div>
                    <div className="partial-page main-content-padding"></div>
                    <div className="partial-page main-content-padding" style={{paddingBottom: 50}}>
                        <a href="http://www.facebook.com/replicacovers" target="_blank"><img className="image-social-media" src={Facebook} /></a>
                        <a href="http://www.twitter.com/replicacovers" target="_blank"><img className="image-social-media" src={Twitter} /></a>
                        <a href="http://www.instagram.com/replicacovers" target="_blank"><img className="image-social-media" src={Instagram} /></a>
                        <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank"><img className="image-social-media" src={YouTube} /></a>
                    </div>
                </div>
            );
        }

        return (
            <div className="partial-page-mobile main-content-padding">
                <div className="full-page main-content-margin div-border">
                    <WhiteH4 content="BOOKING" />
                    <MobileWhiteLabel style={{margin: 0, paddingLeft: 10}}><b>email: </b><a href="info@replicacovers.com">info@replicacovers.com</a></MobileWhiteLabel>
                    <MobileWhiteLabel style={{paddingLeft: 10}}><b>phone: </b>Lex on 07588 856281 or Pete on 07780508496</MobileWhiteLabel>
                    <WhiteH5 content="Please drop us an email or ring Lex or Pete if you would like to make an enquiry" />
                    <hr size="1" width="50%" color="white" />
                    <WhiteH5 content="Extra songs can be learnt for a first dance at a wedding as long as enough notice is given." />
                    <WhiteH5 content="Extended sets" />
                    <WhiteH5 content="Special song requests" />
                    <WhiteH5 content="Stage lighting" />
                    <WhiteH5 content="Disco (with or without DJ)" />
                    {/* <hr size="1" width="50%" color="white" />
                    <MobileWhiteLabel>We also offer a full 90's setlist as part of our 90's project - visit <a href="http://www.resurrection90s.com" target="_blank">www.resurrection90s.com</a></MobileWhiteLabel> */}
                </div>
                <div className="full-page main-content-padding" style={{paddingBottom: 50}}>
                    <a href="http://www.facebook.com/replicacovers" target="_blank"><img className="image-social-media" src={Facebook} /></a>
                    <a href="http://www.twitter.com/replicacovers" target="_blank"><img className="image-social-media" src={Twitter} /></a>
                    <a href="http://www.instagram.com/replicacovers" target="_blank"><img className="image-social-media" src={Instagram} /></a>
                    <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank"><img className="image-social-media" src={YouTube} /></a>
                </div>
            </div>
        );
    }

    render() {
        const { windowWidth } = this.state;

        const styles = {
            showFullVersion: windowWidth > 1680,
            showVersion1: windowWidth > 1543 && windowWidth <= 1680,
            showVersion2: windowWidth > 1400 && windowWidth <= 1543,
            showVersion3: windowWidth > 1200 && windowWidth <= 1400,
            showMidVersion: windowWidth > 900 && windowWidth <= 1200,
            showTabVersion: windowWidth > 480 && windowWidth <= 900
        };

        return (
            <div className="content">
                {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion ? (
                    <Header content="Contact" />
                ) : (
                    <MobileHeader content="Contact" />
                )}
                {this.mainContent(styles)}
            </div>

        );
      }
}

export default Contact;