import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Live from './components/Live/Live';
import Songs from './components/Songs/Songs';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';

class App extends Component {
  render() {
    return (
      <div>
        <Route exact path='/' title="Home" component={Home}/>
        <Route path='/about' title="About" component={About}/>
        <Route path='/live' title="Live" component={Live}/>
        <Route path='/songs' title="Songs" component={Songs}/>
        <Route path='/gallery' title="Gallery" component={Gallery}/>
        <Route path='/contact' title="Contact" component={Contact}/>
      </div>
    );
  }
}

export default App;
