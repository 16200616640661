import React, { Component } from 'react';
import '../../App.css';
import styled from 'styled-components';
import { Header, MobileHeader } from '../generic/header';
import { WhiteH2, WhiteH4, WhiteH5 } from '../generic/heading';
import AudioPlayer from 'react-modular-audio-player';
import { Playlist } from '../../assets/songs/songs';
import SomebodyToldMe from '../../assets/songs/SomebodyToldMe.jpg';
import OneDayLikeThis from '../../assets/songs/OneDayLikeThis.jpg';
import LoveMeLikeYou from '../../assets/songs/LoveMeLikeYou.jpg';

const WhiteLabel = styled.label`
    font-size: 24px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const MobileWhiteLabel = styled.label`
    font-size: 20px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: left;
`;

class Songs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: 0,
          windowHeight: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

        fetch(process.env.PUBLIC_URL + '/data/songs.json')
        .then(res => res.json())
        .then(this.onLoad);
    }

    parseData (response) {
        return response;
    }

    onLoad = (data) => {
        this.setState({
        data: this.parseData(data)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({ windowWidth, windowHeight });
    }

    mainContent(styles, data) {
        if (styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion) {
            return (
                <div className="full-page">
                    <div className="full-page main-content-padding">
                            <WhiteH2 content="This is a current selection of songs that we play. This list is regularly changing." />
                        </div>
                        <div className="full-page main-content-padding">
                            {data.map((record, index) => (
                                <WhiteLabel key={index}><b>{record.artist}</b> - {record.song}</WhiteLabel>
                            ))}
                        </div>
                        {/* <div className="full-page main-content-padding">
                            <WhiteLabel>We can also offer a huge range of 90's songs from our other band Resurrection<br />
                                        Visit <a href="http://www.resurrection90s.com" target="_blank">www.resurrection90s.com</a> for details<br />
                                        Songs from groups such as Oasis, Pulp, Blur, Suede, Ash, Nirvana, Ocean Colour Scene, Charlatans, Bluetones, Dodgy, James, La's, Kula Shaker, Primal Scream, Radiohead, Supergrass, Stone Roses, etc.</WhiteLabel>
                            </div> */}
                    {/* <div className="split-page">
                        <div className="full-page main-content-padding">
                            <WhiteH2 content="Studio demo songs." />
                        </div>
                        <div className="full-page main-content-padding">
                            <img src={SomebodyToldMe} width="33%" />
                            <img src={OneDayLikeThis} width="33%" />
                            <img src={LoveMeLikeYou} width="33%" />
                            <AudioPlayer 
                                audioFiles={Playlist}
                                playerWidth="100%"
                                iconSize="1.5rem"
                                fontSize="1rem"
                                sliderClass="invert-blue-grey"
                            />
                        </div>
                        <div className="full-page main-content-padding">
                            <WhiteH2 content="Lockdown sessions." />
                        </div>
                        <div className="full-page main-content">
                            <WhiteLabel><a href="https://www.youtube.com/watch?v=CFItOPtM_Cs" target="_blank">Elbow - Grounds For Divorce</a></WhiteLabel>
                            <WhiteLabel><a href="https://www.youtube.com/watch?v=O-nn4ANyZnc" target="_blank">New Radicals - You Get What You Give</a></WhiteLabel>
                            <WhiteLabel><a href="https://www.youtube.com/watch?v=GjxKig7i3y8" target="_blank">Dandy Warhols - Bohemian Like You</a></WhiteLabel>
                        </div>
                    </div>
                    <div className="split-page">
                        <div className="full-page main-content-padding">
                            <WhiteH2 content="This is a current selection of songs that we play. This list is regularly changing." />
                        </div>
                        <div className="full-page main-content-padding">
                            {data.map((record, index) => (
                                <WhiteLabel key={index}><b>{record.artist}</b> - {record.song}</WhiteLabel>
                            ))}
                        </div>
                        <div className="full-page main-content-padding">
                            <WhiteLabel>We can also offer a huge range of 90's songs from our other band Resurrection<br />
                                        Visit <a href="http://www.resurrection90s.com" target="_blank">www.resurrection90s.com</a> for details<br />
                                        Songs from groups such as Oasis, Pulp, Blur, Suede, Ash, Nirvana, Ocean Colour Scene, Charlatans, Bluetones, Dodgy, James, La's, Kula Shaker, Primal Scream, Radiohead, Supergrass, Stone Roses, etc.</WhiteLabel>
                        </div>
                            </div> */}
                </div>
            );
        }

        return (
            <div className="full-page">
                {/* <div className="partial-page main-content-padding">
                    <WhiteH4 content="Studio demo songs." />
                </div>
                <div className="partial-page main-content-padding">
                    <img src="../../assets/songs/SomebodyToldMe.jpg" width="33%" />
                    <img src="../../assets/songs/OneDayLikeThis.jpg" width="33%" />
                    <img src="../../assets/songs/LoveMeLikeYou.jpg" width="33%" />
                    <AudioPlayer 
                        audioFiles={Playlist}
                        playerWidth="100%"
                    />
                </div>
                <div className="partial-page main-content-padding">
                    <WhiteH4 content="Lockdown sessions." />
                </div>
                <div className="partial-page main-content">
                    <MobileWhiteLabel><a href="https://www.youtube.com/watch?v=CFItOPtM_Cs" target="_blank">Elbow - Grounds For Divorce</a></MobileWhiteLabel>
                    <MobileWhiteLabel><a href="https://www.youtube.com/watch?v=O-nn4ANyZnc" target="_blank">New Radicals - You Get What You Give</a></MobileWhiteLabel>
                    <MobileWhiteLabel><a href="https://www.youtube.com/watch?v=GjxKig7i3y8" target="_blank">Dandy Warhols - Bohemian Like You</a></MobileWhiteLabel>
                </div>
                <div className="partial-page main-content-padding">
                    <hr size="1" width="100%" color="white" />
        </div> */}
                <div className="partial-page main-content-padding">
                    <WhiteH4 content="This is a current selection of songs that we play. This list is regularly changing." />
                </div>
                <div className="partial-page main-content-padding">
                    {data.map((record, index) => (
                        <MobileWhiteLabel key={index}><b>{record.artist}</b> - {record.song}</MobileWhiteLabel>
                    ))}
                </div>
                {/* <div className="partial-page main-content-padding">
                    <WhiteH5 content="We can also offer a huge range of 90's songs from our other band Resurrection" />
                    <a href="http://www.resurrection90s.com" target="_blank"><WhiteH5 content="www.resurrection90s.com" /></a>
                    <WhiteH5 content="Songs from groups such as Oasis, Pulp, Blur, Suede, Ash, Nirvana, Ocean Colour Scene, Charlatans, Bluetones, Dodgy, James, La's, Kula Shaker, Primal Scream, Radiohead, Supergrass, Stone Roses, etc." />
                    </div> */}
            </div>
        );
    }

    render() {
        const { data } = this.state;

        return data ?
        this.renderSongs(data) :
        this.renderLoading()
    }

    renderSongs(data) {
        const { windowWidth } = this.state;

        const styles = {
            showFullVersion: windowWidth > 1680,
            showVersion1: windowWidth > 1543 && windowWidth <= 1680,
            showVersion2: windowWidth > 1400 && windowWidth <= 1543,
            showVersion3: windowWidth > 1200 && windowWidth <= 1400,
            showMidVersion: windowWidth > 900 && windowWidth <= 1200,
            showTabVersion: windowWidth > 480 && windowWidth <= 900
        };

        return (
            <div className="content">
                {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion ? (
                    <Header content="Songs" />
                ) : (
                    <MobileHeader content="Songs" />
                )}
                {this.mainContent(styles, data)}
            </div>

        );
    }

    renderLoading() {
        return <div>Loading...</div>
    }
}

export default Songs;