import React, { Component } from 'react';
import '../../App.css';
import styled from 'styled-components';
import { Header, MobileHeader } from '../generic/header';
import { WhiteHeaderLabel, FlexibleWhiteLabel } from '../generic/label';
import { WhiteH5 } from '../generic/heading';
import Roger from '../../assets/photos/Roger.jpg';
import Lex from '../../assets/photos/Lex.jpg';
import Matt from '../../assets/photos/Matt.jpg';
import Pete from '../../assets/photos/Pete.jpg';
import Ritchie from '../../assets/photos/Ritchie.jpg';

const DefaultTile = styled.div`
    display: inline-block;
    height: 100%;
    width: ${props => props.width};
    margin: 10px;
    border: solid 6px white; 
    clip-path: polygon( 
        calc(0% + 5px) calc(0% + 5px), /* top left */
        calc(100% - 5px) calc(0% + 5px), /* top right */
        calc(100% - 5px) calc(100% - 5px), /* bottom right */
        calc(0% + 5px) calc(100% - 5px) /* bottom left */
    );
    transition: clip-path 0.4s linear;
    color: black;
    font-family: 'AmericanTypewriter';
`;

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: 0,
          windowHeight: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({ windowWidth, windowHeight });
    }

    render() {
        const { windowWidth } = this.state;

        const styles = {
            showFullVersion: windowWidth > 1680,
            showVersion1: windowWidth > 1543 && windowWidth <= 1680,
            showVersion2: windowWidth > 1400 && windowWidth <= 1543,
            showVersion3: windowWidth > 1200 && windowWidth <= 1400,
            showMidVersion: windowWidth > 900 && windowWidth <= 1200,
            showTabVersion: windowWidth > 480 && windowWidth <= 900
        };

        return (
            <div className="content">
                {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion ? (
                    <Header content="About" />
                ) : (
                    <MobileHeader content="About" />
                )}
                <div className="full-page main-content">
                <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-about-mid"
                        : styles.showTabVersion ? "tile-layout-tab"
                        : "tile-layout-about-mob"}>
                        <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                            <div>
                                <WhiteHeaderLabel content="Roger" />
                            </div>
                            <div>
                                <img className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "image-about" : "image-about-mob"} src={Roger} />
                            </div>
                            <div>
                                <FlexibleWhiteLabel content="Vocals, Percussion" fontsize={styles.showVersion2 ? "14px" : styles.showVersion3 ? "12px" : "16px"} fontweight="normal" />
                            </div>   
                        </DefaultTile>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-about-mid"
                        : styles.showTabVersion ? "tile-layout-about-tab"
                        : "tile-layout-about-mob"}>
                        <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                            <div>
                                <WhiteHeaderLabel content="Lex" />
                            </div>
                            <div>
                                <img className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "image-about" : "image-about-mob"} src={Lex} />
                            </div>
                            <div>
                                <FlexibleWhiteLabel content="Lead Guitar" fontsize="16px" fontweight="normal" />
                            </div> 
                        </DefaultTile>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-about-mid"
                        : styles.showTabVersion ? "tile-layout-about-tab"
                        : "tile-layout-about-mob"}>
                        <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                            <div>
                                <WhiteHeaderLabel content="Matt" />
                            </div>
                            <div>
                                <img className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "image-about" : "image-about-mob"} src={Matt} />
                            </div>
                            <div>
                                <FlexibleWhiteLabel content="Rhythm Guitar, Vocals" fontsize={styles.showVersion2 ? "14px" : styles.showVersion3 ? "12px" : "16px"} fontweight="normal" />
                            </div> 
                        </DefaultTile>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-about-mid"
                        : styles.showTabVersion ? "tile-layout-about-tab"
                        : "tile-layout-about-mob"}>
                        <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                            <div>
                                <WhiteHeaderLabel content="Pete" />
                            </div>
                            <div>
                                <img className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "image-about" : "image-about-mob"} src={Pete} />
                            </div>
                            <div>
                                <FlexibleWhiteLabel content="Bass Guitar" fontsize="16px" fontweight="normal" />
                            </div> 
                        </DefaultTile>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-about-mid"
                        : styles.showTabVersion ? "tile-layout-about-tab"
                        : "tile-layout-about-mob"}>
                        <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                            <div>
                                <WhiteHeaderLabel content="Ritchie" />
                            </div>
                            <div>
                                <img className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "image-about" : "image-about-mob"} src={Ritchie} />
                            </div>
                            <div>
                                <FlexibleWhiteLabel content="Drums, Percussion" fontsize={styles.showVersion2 ? "14px" : styles.showVersion3 ? "12px" : "16px"} fontweight="normal" />
                            </div> 
                        </DefaultTile>
                    </div>
                </div>
                <br /><br />
                <div className={styles.showFullVersion ? "partial-page" : "partial-page-mobile"}>
                    <WhiteH5 content="REPLICA is made up of experienced musicians who have played in various bands over the years. We are Leicester’s top Indie / Rock covers band with one of the most exciting set lists around." />
                    <WhiteH5 content="REPLICA has a well-earned reputation as one of the best live Cover Bands nationally too having headlined some of the largest events in the UK. This includes runner-up in O’Neill’s national “Battle of Bands” competition in London and headline band at Oxford University Summer Ball for two consecutive years." />
                    <WhiteH5 content="REPLICA has a strong reputation to maintain and always guarantee a highly charged and electric evening that won't be forgotten. We pride ourselves on working to an exceptionally high standard both musically and professionally." />
                    <WhiteH5 content="REPLICA play totally live, which is the only way to get that energy and excitement that a live band provides. We know how to put on a great show by playing authentic versions of all the best indie-rock hits from bands such as Arctic Monkeys, Blur, Oasis, Pulp, and Kasabian.  We also do our own versions of more main-stream numbers. So if there's a special song that doesn't sound like it would fall into our normal range and style, give us a call and we'll work some magic for you!" />
                    <WhiteH5 content="REPLICA is the ideal band to create the ultimate party atmosphere – whether it be a private function, large corporate event, wedding or University Ball." />
                </div>
                <div className="full-page main-content"><br /></div>
            </div>

        );
      }
}

export default About;