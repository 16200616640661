
import React, { Component } from 'react';
import '../../App.css';
import '../../image-gallery.css';
import styled from 'styled-components';
import { Header, MobileHeader } from '../generic/header';
import { FlexibleWhiteLabel } from '../generic/label';
import ImageGallery from 'react-image-gallery';
import { Images } from '../../assets/photos/photos';

const DefaultTile = styled.div`
    display: inline-block;
    height: 100%;
    width: ${props => props.width};
    margin: 10px;
    border: solid 6px white; 
    clip-path: polygon( 
        calc(0% + 5px) calc(0% + 5px), /* top left */
        calc(100% - 5px) calc(0% + 5px), /* top right */
        calc(100% - 5px) calc(100% - 5px), /* bottom right */
        calc(0% + 5px) calc(100% - 5px) /* bottom left */
    );
    transition: clip-path 0.4s linear;
    color: black;
    font-family: 'AmericanTypewriter';
`;

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: 0,
          windowHeight: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({ windowWidth, windowHeight });
    }

    mainContent(styles) {
        if (styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3) {
            return (
                <div className="div-gallery main-content-padding">
                    {/* <DefaultTile width="100%">
                        <ImageGallery items={Images} />  
            </DefaultTile>
                    <FlexibleWhiteLabel fontsize="20px" fontweight="normal" content="General Live Photos" /> */}
                    <FlexibleWhiteLabel fontsize="20px" fontweight="normal" content="New photos coming soon" />
                </div>
            );
        }

        return (
            <div className="partial-page-mobile main-content-padding">
                {/* <DefaultTile width="90%">
                    <ImageGallery items={Images} />  
                </DefaultTile>
        <FlexibleWhiteLabel fontsize="20px" fontweight="normal" content="General Live Photos" /> */}
        <FlexibleWhiteLabel fontsize="20px" fontweight="normal" content="New photos coming soon" />
            </div>
        );
    }
    
    render() {
        const { windowWidth } = this.state;

        const styles = {
            showFullVersion: windowWidth > 1680,
            showVersion1: windowWidth > 1543 && windowWidth <= 1680,
            showVersion2: windowWidth > 1400 && windowWidth <= 1543,
            showVersion3: windowWidth > 1200 && windowWidth <= 1400,
            showMidVersion: windowWidth > 900 && windowWidth <= 1200,
            showTabVersion: windowWidth > 480 && windowWidth <= 900
        };

        return (
            <div className="content">
                {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion ? (
                    <Header content="Gallery" />
                ) : (
                    <MobileHeader content="Gallery" />
                )}
                {this.mainContent(styles)}
            </div>

        );
    }
}

export default Gallery;