import React, { Component } from 'react';
import styled from 'styled-components';

const DefaultWhiteH1 = styled.h1`
    font-family: 'AmericanTypewriter';
    color: white;
`;

const DefaultWhiteH2 = styled.h2`
    font-family: 'AmericanTypewriter';
    color: white;
`;

const DefaultWhiteH3 = styled.h3`
    font-family: 'AmericanTypewriter';
    color: white;
`;

const DefaultWhiteH4 = styled.h4`
    font-family: 'AmericanTypewriter';
    color: white;
`;

const DefaultWhiteH5 = styled.h5`
    font-family: 'AmericanTypewriter';
    color: white;
`;

const DefaultBlackH1 = styled.h1`
    font-family: 'AmericanTypewriter';
    color: black;
`;

const DefaultBlackH2 = styled.h2`
    font-family: 'AmericanTypewriter';
    color: black;
`;

const DefaultBlackH3 = styled.h3`
    font-family: 'AmericanTypewriter';
    color: black;
`;

const DefaultBlackH4 = styled.h4`
    font-family: 'AmericanTypewriter';
    color: black;
`;

const DefaultBlackH5 = styled.h5`
    font-family: 'AmericanTypewriter';
    color: black;
    text-align: left;
    padding: 0 0 0 10px;
`;

class WhiteH1 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteH1>
                {content}
            </DefaultWhiteH1>
        );
    }
}

class WhiteH2 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteH2>
                {content}
            </DefaultWhiteH2>
        );
    }
}

class WhiteH3 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteH3>
                {content}
            </DefaultWhiteH3>
        );
    }
}

class WhiteH4 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteH4>
                {content}
            </DefaultWhiteH4>
        );
    }
}

class WhiteH5 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultWhiteH5>
                {content}
            </DefaultWhiteH5>
        );
    }
}

class BlackH1 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackH1>
                {content}
            </DefaultBlackH1>
        );
    }
}

class BlackH2 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackH2>
                {content}
            </DefaultBlackH2>
        );
    }
}

class BlackH3 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackH3>
                {content}
            </DefaultBlackH3>
        );
    }
}

class BlackH4 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackH4>
                {content}
            </DefaultBlackH4>
        );
    }
}

class BlackH5 extends Component {
    render() {
        const { 
            content,
        } = this.props;

        return (
            <DefaultBlackH5>
                {content}
            </DefaultBlackH5>
        );
    }
}

export { WhiteH1, WhiteH2, WhiteH3, WhiteH4, WhiteH5, BlackH1, BlackH2, BlackH3, BlackH4, BlackH5 };