import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '../../App.css';
import RepName from '../../assets/logos/RepLogoWhiteClear.png';
import { BlackLabel, NavWhiteLabel, MobileBlackLabel, MobileNavWhiteLabel } from '../generic/label';

const DefaultHeader = styled.header`
    height:200px;
    width:100%;
    position: relative;
`;

const SmallHeader = styled.header`
    height:150px;
    width:100%;
    position: relative;
`;

class Header extends Component {
    render() {
        const { 
            content,
        } = this.props;

        const isAbout = content === 'About';
        const isLive = content === 'Live';
        const isSongs = content === 'Songs';
        const isGallery = content === 'Gallery';
        const isContact = content === 'Contact';

        return (
            <DefaultHeader>
                <nav>
                    <div className="header-content-logo">
                        <Link to="/">
                            <img src={RepName} className="header-name"></img>
                        </Link>
                    </div>
                    <div className="header-content-nav">
                        <div className="header-content-label">
                            {isAbout ? (
                                <BlackLabel content="About" />
                            ) : (
                                <Link to="/about">
                                    <NavWhiteLabel content="About" />
                                </Link>
                            )}
                            {isLive ? (
                                <BlackLabel content="Live" />
                            ) : (
                                <Link to="/live">
                                    <NavWhiteLabel content="Live" />
                                </Link>
                            )}
                            {isSongs ? (
                                <BlackLabel content="Songs" />
                            ) : (
                                <Link to="/songs">
                                    <NavWhiteLabel content="Songs" />
                                </Link>
                            )}
                            {isGallery ? (
                                <BlackLabel content="Gallery" />
                            ) : (
                                <Link to="/gallery">
                                    <NavWhiteLabel content="Gallery" />
                                </Link>
                            )}
                            {isContact ? (
                                <BlackLabel content="Contact" />
                            ) : (
                                <Link to="/contact">
                                    <NavWhiteLabel content="Contact" />
                                </Link>
                            )}
                        </div>
                    </div>
                </nav>
            </DefaultHeader>
        );
    }
}

class MobileHeader extends Component {
    render() {
        const { 
            content,
        } = this.props;

        const isAbout = content === 'About';
        const isLive = content === 'Live';
        const isSongs = content === 'Songs';
        const isGallery = content === 'Gallery';
        const isContact = content === 'Contact';

        return (
            <SmallHeader>
                <nav>
                    <div className="header-content-logo-mob">
                        <Link to="/">
                            <img src={RepName} className="header-name-mob"></img>
                        </Link>
                    </div>
                    <div className="header-content-nav-mob">
                        {isAbout ? (
                            <MobileBlackLabel content="About" />
                        ) : (
                            <Link to="/about">
                                <MobileNavWhiteLabel content="About" />
                            </Link>
                        )}
                        {isLive ? (
                            <MobileBlackLabel content="Live" />
                        ) : (
                            <Link to="/live">
                                <MobileNavWhiteLabel content="Live" />
                            </Link>
                        )}
                        {isSongs ? (
                            <MobileBlackLabel content="Songs" />
                        ) : (
                            <Link to="/songs">
                                <MobileNavWhiteLabel content="Songs" />
                            </Link>
                        )}
                        {isGallery ? (
                            <MobileBlackLabel content="Gallery" />
                        ) : (
                            <Link to="/gallery">
                                <MobileNavWhiteLabel content="Gallery" />
                            </Link>
                        )}
                        {isContact ? (
                            <MobileBlackLabel content="Contact" />
                        ) : (
                            <Link to="/contact">
                                <MobileNavWhiteLabel content="Contact" />
                            </Link>
                        )}
                    </div>
                </nav>
            </SmallHeader>
        );
    }
}

export { Header, MobileHeader };