import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import styled from 'styled-components';
import { Header, MobileHeader } from '../generic/header';
import { StandardWhiteLabel, FlexibleWhiteLabel } from '../generic/label';
import { WhiteH1, WhiteH3, WhiteH5 } from '../generic/heading';
import Roger from '../../assets/photos/Roger.jpg';
import Lex from '../../assets/photos/Lex.jpg';
import Matt from '../../assets/photos/Matt.jpg';
import Pete from '../../assets/photos/Pete.jpg';
import Ritchie from '../../assets/photos/Ritchie.jpg';
import AudioPlayer from 'react-modular-audio-player';
import { Playlist } from '../../assets/songs/songs';
import SomebodyToldMe from '../../assets/songs/SomebodyToldMe.jpg';
import OneDayLikeThis from '../../assets/songs/OneDayLikeThis.jpg';
import LoveMeLikeYou from '../../assets/songs/LoveMeLikeYou.jpg';
import Replica from '../../assets/photos/Replica.jpg';
import Facebook from '../../assets/logos/facebook.png';
import Twitter from '../../assets/logos/twitter.png';
import Instagram from '../../assets/logos/instagram.png';
import YouTube from '../../assets/logos/youtube.png';

const DefaultTile = styled.div`
    display: inline-block;
    height: 100%;
    width: ${props => props.width};
    margin: 10px;
    border: solid 6px white; 
    clip-path: polygon( 
        calc(0% + 5px) calc(0% + 5px), /* top left */
        calc(100% - 5px) calc(0% + 5px), /* top right */
        calc(100% - 5px) calc(100% - 5px), /* bottom right */
        calc(0% + 5px) calc(100% - 5px) /* bottom left */
    );
    transition: clip-path 0.4s linear;
    color: black;
    font-family: 'AmericanTypewriter';

    &:hover {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
`;

const MobLargeWhiteLabel = styled.label`
    font-size: 22px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const MobMediumWhiteLabel = styled.label`
    font-size: 20px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const MobSmallWhiteLabel = styled.label`
    font-size: 16px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: 0,
          windowHeight: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({ windowWidth, windowHeight });
    }

    mainContent(styles) {
        if (styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3) {
            return (
                <div className="full-page">
                    <div className="half-page main-content-padding">
                        <WhiteH1 content="The best 'indie-rock' covers from the nineties and the noughties." />
                    </div>
                </div>
            );
        }

        return (
            <div className="full-page main-content-padding">
                <WhiteH3 content="The best 'indie-rock' covers from the nineties and the noughties." />
            </div>
        );
    }

    render() {
        const { windowWidth } = this.state;

        const styles = {
            showFullVersion: windowWidth >= 1680,
            showVersion1: windowWidth > 1543 && windowWidth < 1680,
            showVersion2: windowWidth > 1400 && windowWidth <= 1543,
            showVersion3: windowWidth > 1200 && windowWidth <= 1400,
            showMidVersion: windowWidth > 900 && windowWidth <= 1200,
            showTabVersion: windowWidth > 600 && windowWidth <= 900
        };

        return (
            <div className="content">
                {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion ? (
                    <Header content="Home" />
                ) : (
                    <MobileHeader content="Home" />
                )}
                {this.mainContent(styles)}
                <div className="full-page main-content">
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-mid"
                        : styles.showTabVersion ? "tile-layout-tab"
                        : "tile-layout-mob"}>
                        <Link to="/about">
                            <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                                <div>
                                    <FlexibleWhiteLabel content="About" fontsize="20px" fontweight="bold" />
                                </div>
                                <div className="full-content live-content-padding">
                                    <img src={Roger} width="18%" />
                                    <img src={Lex} width="18%" />
                                    <img src={Matt} width="18%" />
                                    <img src={Pete} width="18%" />
                                    <img src={Ritchie} width="18%" />
                                    {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? (
                                        <MobMediumWhiteLabel style={{paddingBottom: 22}}></MobMediumWhiteLabel>
                                    ) : (
                                        ""
                                    )}
                                    <MobLargeWhiteLabel style={{margin: 0}}>Roger | Lex | Matt | Pete | Ritchie</MobLargeWhiteLabel>
                                </div>
                                <div>
                                    <FlexibleWhiteLabel content="More Info >>" fontsize="20px" fontweight="normal" />
                                </div>
                            </DefaultTile>
                        </Link>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-mid"
                        : styles.showTabVersion ? "tile-layout-tab"
                        : "tile-layout-mob"}>
                        <Link to="/live">
                            <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                                <div>
                                    <FlexibleWhiteLabel content="Live" fontsize="20px" fontweight="bold" />
                                </div>
                                <div className="full-page live-content-padding">
                                    <MobMediumWhiteLabel style={{margin: 0}}>Next Gig:</MobMediumWhiteLabel>
                                    {styles.showVersion2 || styles.showVersion3 ? (
                                        <MobMediumWhiteLabel style={{margin: 0}}><b>The Donkey</b></MobMediumWhiteLabel>
                                    ) : (
                                        <MobLargeWhiteLabel style={{margin: 0}}><b>The Donkey</b></MobLargeWhiteLabel>
                                    )}
                                    {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 ? (
                                        <MobMediumWhiteLabel style={{margin: 0}}>14 Sep 2024<br />Free entry</MobMediumWhiteLabel>
                                    ) : (
                                        <MobSmallWhiteLabel style={{margin: 0}}>14 Sep 2024 | Free entry</MobSmallWhiteLabel>
                                    )}
                                    {styles.showVersion3 ? (
                                        ""
                                    ) : (
                                        <MobMediumWhiteLabel style={{paddingBottom: 16}}></MobMediumWhiteLabel>
                                    )}
                                    {/* <MobMediumWhiteLabel style={{margin: 0}}>No gigs</MobMediumWhiteLabel>
                                    {styles.showVersion2 || styles.showVersion3 ? (
                                        <MobMediumWhiteLabel style={{margin: 0}}>currently scheduled</MobMediumWhiteLabel>
                                    ) : (
                                        <MobLargeWhiteLabel style={{margin: 0}}>currently scheduled</MobLargeWhiteLabel>
                                    )}
                                    {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 ? (
                                        <MobMediumWhiteLabel style={{margin: 0}}>this year<br />due to COVID-19</MobMediumWhiteLabel>
                                    ) : (
                                        <MobSmallWhiteLabel style={{margin: 0}}>this year due to COVID-19</MobSmallWhiteLabel>
                                    )}
                                    {styles.showVersion3 ? (
                                        ""
                                    ) : (
                                        <MobMediumWhiteLabel style={{paddingBottom: 16}}></MobMediumWhiteLabel>
                                    )} */}
                                </div>
                                <div>
                                    <FlexibleWhiteLabel content="More Gigs >>" fontsize="20px" fontweight="normal" />
                                </div>
                            </DefaultTile>
                        </Link>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-mid"
                        : styles.showTabVersion ? "tile-layout-tab"
                        : "tile-layout-mob"}>
                        <Link to="/songs">
                            <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                                <div>
                                    <FlexibleWhiteLabel content="Songs" fontsize="20px" fontweight="bold" />
                                </div>
                                <div className="full-page live-content-padding">
                                    <MobMediumWhiteLabel style={{margin: 0}}>Click here to</MobMediumWhiteLabel>
                                    {styles.showVersion2 || styles.showVersion3 ? (
                                        <MobMediumWhiteLabel style={{margin: 0}}>view our full</MobMediumWhiteLabel>
                                    ) : (
                                        <MobMediumWhiteLabel style={{margin: 0}}>view our full</MobMediumWhiteLabel>
                                    )}
                                    {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 ? (
                                        <MobMediumWhiteLabel style={{margin: 0}}>list of current</MobMediumWhiteLabel>
                                    ) : (
                                        <MobMediumWhiteLabel style={{margin: 0}}>list of current songs</MobMediumWhiteLabel>
                                    )}
                                    {styles.showFullVersion || styles.showVersion2 || styles.showVersion3 ? (
                                        <MobLargeWhiteLabel style={{margin: 0}}>songs</MobLargeWhiteLabel>
                                    ) : (
                                        ""
                                    )}
                                    {styles.showVersion3 ? (
                                        ""
                                    ) : (
                                        <MobMediumWhiteLabel style={{paddingBottom: 16}}></MobMediumWhiteLabel>
                                    )}
                                </div>
                                {/* {styles.showFullVersion ? (
                                    <div className="full-content live-content">
                                        <img src={SomebodyToldMe} width="29%" />
                                        <img src={OneDayLikeThis} width="29%" />
                                        <img src={LoveMeLikeYou} width="29%" />
                                        <AudioPlayer 
                                            audioFiles={Playlist}
                                            playerWidth="15rem"
                                            iconSize="1rem"
                                            fontSize="0.5rem"
                                            sliderClass="invert-blue-grey"
                                        />
                                        <MobMediumWhiteLabel style={{margin: 0, paddingBottom: 4}}>Studio demo songs</MobMediumWhiteLabel>
                                        <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank">Lockdown sessions</a>
                                    </div>
                                ) : styles.showVersion1 || styles.showVersion2 ? (
                                    <div className="full-content live-content-padding">
                                        <img src={SomebodyToldMe} width="33%" />
                                        <img src={OneDayLikeThis} width="33%" />
                                        <img src={LoveMeLikeYou} width="33%" />
                                        <AudioPlayer 
                                            audioFiles={Playlist}
                                            playerWidth="90%"
                                            iconSize="1rem"
                                            fontSize="0.5rem"
                                            sliderClass="invert-blue-grey"
                                        />
                                        <MobMediumWhiteLabel style={{margin: 0}}>Studio demo songs</MobMediumWhiteLabel>
                                        <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank">Lockdown sessions</a>
                                    </div>
                                ) : styles.showVersion3 ? (
                                    <div style={{paddingBottom: 54}}>
                                        <img src={SomebodyToldMe} width="33%" />
                                        <img src={OneDayLikeThis} width="33%" />
                                        <img src={LoveMeLikeYou} width="33%" />
                                        <AudioPlayer 
                                            audioFiles={Playlist}
                                            playerWidth="90%"
                                            iconSize="1rem"
                                            fontSize="0.5rem"
                                            sliderClass="invert-blue-grey"
                                        />
                                        <MobMediumWhiteLabel style={{margin: 0}}>Studio demo songs</MobMediumWhiteLabel>
                                        <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank">Lockdown sessions</a>
                                    </div>
                                ) : (
                                    <div>
                                        <img src={SomebodyToldMe} width="29%" />
                                        <img src={OneDayLikeThis} width="29%" />
                                        <img src={LoveMeLikeYou} width="29%" />
                                        <AudioPlayer 
                                            audioFiles={Playlist}
                                            pplayerWidth="90%"
                                            iconSize="1rem"
                                            fontSize="0.5rem"
                                            sliderClass="invert-blue-grey"
                                        />
                                        {styles.showMidVersion ? (
                                            ""
                                        ) : (
                                            // <FlexibleWhiteLabel content="Studio demo songs" fontsize="14px" fontweight="normal" />
                                            <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank">View our Lockdown sessions here</a>
                                        )}
                                    </div>
                                )} */}
                                <div>
                                    <FlexibleWhiteLabel content="More Songs >>" fontsize="20px" fontweight="normal" />
                                </div>
                            </DefaultTile>
                        </Link>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-mid"
                        : styles.showTabVersion ? "tile-layout-tab"
                        : "tile-layout-mob"}>
                        <Link to="/gallery">
                            <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                                <div>
                                    <FlexibleWhiteLabel content="Gallery" fontsize="20px" fontweight="bold" />
                                </div>
                                {styles.showFullVersion ? (
                                    <div style={{paddingBottom: 66}}>
                                        <img className="image-gallery-tile" src={Replica} />
                                    </div>
                                ) : styles.showVersion1 || styles.showVersion2 ? (
                                    <div style={{paddingBottom: 78}}>
                                        <img className="image-gallery-tile" src={Replica} />
                                    </div>
                                ) : styles.showVersion3 ? (
                                    <div style={{paddingBottom: 90}}>
                                        <img className="image-gallery-tile" src={Replica} />
                                    </div>
                                ) : (
                                    <div style={{paddingBottom: 10}}>
                                        <img className="image-mob" src={Replica} />
                                    </div>
                                )}
                                <div>
                                    <FlexibleWhiteLabel content="More Photos >>" fontsize="20px" fontweight="normal" />
                                </div>
                            </DefaultTile>
                        </Link>
                    </div>
                    <div className={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "tile-layout"
                        : styles.showMidVersion ? "tile-layout-mid"
                        : styles.showTabVersion ? "tile-layout-tab"
                        : "tile-layout-mob"}>
                        <DefaultTile width={styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? "100%" : "90%"}>
                            <div>
                                <FlexibleWhiteLabel content="Contact" fontsize="20px" fontweight="bold" />
                            </div>
                            <div>
                                {styles.showVersion2 || styles.showVersion3 ? (
                                    <MobSmallWhiteLabel><a href="info@replicacovers.com">info@replicacovers.com</a></MobSmallWhiteLabel>
                                ) : (
                                    <MobMediumWhiteLabel><a href="info@replicacovers.com">info@replicacovers.com</a></MobMediumWhiteLabel>
                                )}
                                <MobMediumWhiteLabel><b>Lex: </b>07588 856281</MobMediumWhiteLabel>
                                <MobMediumWhiteLabel><b>Pete: </b>07780508496</MobMediumWhiteLabel>
                            </div>
                            {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 ? (
                                <div style={{paddingBottom: 19, paddingTop: 30}}>
                                    <a href="http://www.facebook.com/replicacovers" target="_blank"><img className={styles.showVersion3 ? "image-social-media-small" : "image-social-media"} src={Facebook} /></a>
                                    <a href="http://www.twitter.com/replicacovers" target="_blank"><img className={styles.showVersion3 ? "image-social-media-small" : "image-social-media"} src={Twitter} /></a>
                                    <a href="http://www.instagram.com/replicacovers" target="_blank"><img className={styles.showVersion3 ? "image-social-media-small" : "image-social-media"} src={Instagram} /></a>
                                    <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank"><img className={styles.showVersion3 ? "image-social-media-small" : "image-social-media"} src={YouTube} /></a>
                                </div>
                            ) : (
                                <div style={{paddingBottom: 10, paddingTop: 5}}>
                                    <a href="http://www.facebook.com/replicacovers" target="_blank"><img className="image-social-media" src={Facebook} /></a>
                                    <a href="http://www.twitter.com/replicacovers" target="_blank"><img className="image-social-media" src={Twitter} /></a>
                                    <a href="http://www.instagram.com/replicacovers" target="_blank"><img className="image-social-media" src={Instagram} /></a>
                                    <a href="https://www.youtube.com/channel/UCwPPL7X3k2lS7M7Z-LedWIw" target="_blank"><img className="image-social-media" src={YouTube} /></a>
                                </div>
                            )}
                            <Link to="/contact">
                                <div>
                                    <FlexibleWhiteLabel content="More Details >>" fontsize="20px" fontweight="normal" />
                                </div>
                            </Link>
                        </DefaultTile>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;